.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #00000000; /* Transparent background */
    color: #fff;
    padding: 10px 20px;
  }
  
  .navbar-logo img {
    height: auto; /* Ensure logo scales proportionally */
    max-height: 140px; /* Limit maximum height of logo */
  }
  
  .navbar-social-icons {
    display: flex;
    gap: 10px; /* Adjust the gap between social icons */
  }
  
  .navbar-social-icons img {
    height: 80px; /* Adjust height of social icons */
    margin-right: 50px;
  }
  
  .navbar-social-icons a {
    color: #fff;
    text-decoration: none;
  }
  
  .navbar-social-icons a:hover {
    color: #ffcc00; /* Change color on hover */
  }
  
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
      align-items: center;
      padding: 10px; 
    }
  
    .navbar-logo {
      margin-bottom: 10px; /* Add space between logo and social icons */
      /* margin-left: 5%; */
    }
  
    .navbar-social-icons {
   
      gap: 10px; /* Adjust gap between stacked social icons */
    }
  
    .navbar-social-icons img {
      height: 60px; /* Reduce height of social icons for mobile */
      margin-right: 0px;
    }
  }
  