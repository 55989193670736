.App {
  text-align: center;
  width: 100%;
  height: 100vh; /* Using viewport height to cover the entire screen */
  background-image: url(./IMG_1072.PNG);
  background-size: cover; /* Cover the entire container */
  background-repeat: no-repeat; /* Do not repeat the background image */
  background-position: center; /* Center the background image */
}

@media (max-width: 768px) {
  .App {
    text-align: center;
    width: 100%;
    height: 100vh; /* Using viewport height to cover the entire screen */
    background-image: url(./mob.PNG);
    background-size: cover; /* Cover the entire container */
    background-repeat: no-repeat; /* Do not repeat the background image */
    background-position: center; /* Center the background image */
  }
}


