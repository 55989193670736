.body-container {
    display: flex;
    justify-content: flex-start; /* Align content to the left on desktop */
    align-items: center; /* Center vertically */
    height: calc(100vh - 164px); /* Adjust for navbar height if different */
    color: white;
    padding: 0 80px; /* Add padding on left and right */
  }
  
  .content {
    text-align: center;
    max-width: 600px;
    padding: 20px;
    /* background-color: #fff; */
    border-left: 5px solid #333;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .content h1 {
    font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 10px;
  }
  .content h2 {
    font-size: 1rem;
    font-style: italic;
    color: #666;
    margin-bottom: 20px;
  }
  
  .content button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #4CAF50; /* Green background color */
    color: white;
    border: none;
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease-out;
  }
  
  .content button:hover {
    background-color: #45a049; /* Darker green on hover */
    transform: scale(1.05); /* Scale up slightly on hover */
  }
  
  .content button:hover {
    background-color: #555;
  }
  
  @media (max-width: 1024px) {
    .body-container {
      justify-content: center; /* Center content on smaller screens */
      padding: 0 10px; /* Adjust padding */
      
    }
    
    .content {
      text-align: center; /* Center content on smaller screens */
    }
  }
  
  @media (max-width: 768px) {
    .body-container {
        justify-content: center; /* Center content on smaller screens */
        padding: 0 10px; /* Adjust padding */
        height: calc(100vh - 294px); /* Adjust for navbar height if different */
        
      }

      .content {
        margin-top: 320px;
        text-align: center;
        max-width: 600px;
        padding: 20px;
        color: rgb(255, 255, 255);
        background-color: #00000044;
        border-radius: 10px;
        border-left: 5px solid #333;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      }
      .content h1 {
        font-size: 1.5rem;
      line-height: 1.6;
      margin-bottom: 10px;
      }
      .content h2 {
        font-size: 1rem;
        font-style: italic;
        color: #ff0000;
        margin-bottom: 20px;
      }
  }
  